import logo from './logo.svg';
import './App.css';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';

const api_url = "https://hmrc-client-proxy-1-pdfv22ipta-nw.a.run.app/p/star-client-api.untied.io"
//const api_url = "http://localhost:9000/p/star-client-api.untied.io"

var popup = null;

function App() {

  const [page, setPage] = useState(1);
  const [password, setPassword] = useState("")
  const [dob, setDob] = useState();
  const [nino, setNino] = useState();
  const [inviteUrl, setInviteUrl] = useState();
  const [tpId, setTPId] = useState();
  const [latestEmployer, setLatestEmployer] = useState();
  const [statusMessage, setStatusMessage] = useState("");
  const [errorMessage, setError] = useState("");

  const doInvite = () => {
  
    setPage(3);

    const payload = {
        "synchronous": "Y",
        "taxpayers": [
            {"nino": nino, "dob": dob, "email": "", "ref": ""}
        ],      
    }

    const url = api_url + '/invite'
    setStatusMessage('Creating invite')
  
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'secret': password,
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
    .then(data => {

      if (data.status === "success") {
        
        const batch_id = data.batch_id

        const url = api_url + '/batch/' + batch_id 
        fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'secret': password
          },
        }).then(response => response.json())
        .then(data => {

          console.log(data)

          if (data.taxpayers[0].error == "N") {
            const invite_url = data.taxpayers[0].invite_url
            setInviteUrl(invite_url)

            const tp_id = data.taxpayers[0].id
            setTPId(tp_id)

            setPage(4);
          } else {
            //alert("Error: " + data.taxpayers[0].message  )
            setError(data.taxpayers[0].message)
            setPage(7)
          }
        })
        
      } else {
        setError(data.message)
        setPage(7)
      }

    })

  }

  const doUpdate = (start_updates) => {
      // now we need to poll /taxpayer/{tp_id} until the number of updates has increased
      setTimeout(() => {
        const url = api_url + '/taxpayer/' + tpId
        fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'secret': password
          },
        }).then(response => response.json())
        .then(data => {

          const end_updates = data.taxpayerInfo[0].num_data_updates
          if (end_updates > start_updates) {

            // get all data.employments
            var employments = data.employments

            // sort by start_date descending but start_date is format "d MMM yyyy" so we need to convert to date or it will sort alphabetically
            employments = employments.sort((a, b) => {
              const aDate = new Date(a.start_date)
              const bDate = new Date(b.start_date)
              return bDate - aDate
            })

            // get the first one
            const employment = employments[0]
            setLatestEmployer(employment);

            setNino("");
            setDob("");
            setPage(6);

            // cancel client registration
            const url = api_url + '/cancelClient'
            const payload = { "client_id" : tpId }
            fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'secret': password
              },
              body: JSON.stringify(payload),
            }).then(response => response.json())
            .then(data => {
              // console.log(data)
            });


          } else {
            doUpdate(start_updates);
          }
        })
      },1000);
  }

  const scanForAccepted = () => {
    setTimeout(() => {

      const url = api_url + '/taxpayer/' + tpId

      fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'secret': password
        },
      }).then(response => response.json())
      .then(data => {

        console.log(data)

        const registered = data.taxpayerInfo[0].registered ?? 'N'
        if (registered == 'Y') {

          popup.close();
          setStatusMessage('Gathering data ... ')

          const start_updates = data.taxpayerInfo[0].num_data_updates

          const url = api_url + '/updateTaxpayer'
          fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'secret': password
            },
            body: JSON.stringify({ "taxpayer_id" : tpId }),
          }).then(response => response.json())
          .then(data => { 
            doUpdate(start_updates);
          });

        } else {

          // force an update, then check again ... 
          const url = api_url + '/updateTaxpayer'
          fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'secret': password
            },
            body: JSON.stringify({ "taxpayer_id" : tpId }),
          }).then(response => response.json())
          .then(data => { 
            scanForAccepted();
          });

          
        }
      })

    },2000)
  }

  const doRedirect = () => {
    // window.open(inviteUrl, "_blank")

    let w = 600;
    let h = 800;
    const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
    const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);    
    popup = window.open(inviteUrl, "invite", "width="+w+",height="+h+",top="+top+",left="+left);
    
    // sometimes you get a no journey ID error from HMRC and reloading the page works, so wait a short while and load the page in newWin again
    setTimeout(function(){ popup.location.href = inviteUrl; }, 500);     

    setPage(5);
    setStatusMessage('Waiting for authorisation')
    scanForAccepted();
  }

  const getStartDate = (dateString) => {
    //get last 4 digits of dataString
    // const year = dateString.substring(dateString.length - 4);
    return dateString + " or earlier"
  }


  return (
    <div className="App">

      {/* {page === 0 && (
        <div className="mt-4 text-start mx-4">
          <p>Enter your password to continue</p>

          <input type="password" className="me-2" onChange={(e) => setPassword(e.target.value) } />

          <Button onClick={() => setPage(2)}>Next</Button>

        </div>
      )} */}

      {page === 1 && (
        <div className="mt-4 text-start mx-4">
          <p>Welcome to untied star, a secure fully-permissioned consent-based system for HMRC data for lending and other use cases.</p>
          <p>This is a restricted environment made available under NDA to demonstrate the live capability.</p>
          <p>All use is subject to untied's <a href="https://www.untied.io/terms" target="_new">terms and conditions</a> and <a href="https://www.untied.io/privacy" target="_new">privacy policy</a>.</p>

          <Button onClick={() => setPage(2)}>Next</Button>

        </div>
      )}

      {page === 2 && (
        <div className="mt-4 text-start mx-4" style={{ maxWidth: 640 }}>
          <p>Please enter your details</p>
          <div className="container text-start">
            <div className="row mt-4">
              <div className="col">
                National insurance number
              </div>
              <div className="col">
                <input onChange={(e) => setNino(e.target.value) } value={nino}/>
              </div>
            </div>

            <div className="row mt-3 mb-4">
              <div className="col">
                Date of birth
              </div>
              <div className="col">
                <input type="date" onChange={(e) => setDob(e.target.value) } value={dob}/>
              </div>
            </div>


            <div className="row mt-3 mb-4">
              <div className="col">
                Password
              </div>
              <div className="col">
                <input type="password" onChange={(e) => setPassword(e.target.value) }/>
              </div>
            </div>

            <Button onClick={() => doInvite()}>Next</Button>

          </div>

        </div>
      )}

      {page === 3 && (
        <div className="mt-4 text-start mx-4">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>           
          </div>
          <p>Processing, please wait...</p>
        </div>
      )}

      {page == 4 && (
        <div className="mt-4 text-start mx-4">
          <p>When you hit next, a new tab or window will open taken you to HMRC to confirm your data sharing. You will need a Government Gateway account.</p>
          <p>Permission will be complete when you see this screen:</p>
          <img src={require('./images/hmrc-approval.png')} style={{ maxWidth: 640, height:150 }} />

          <p className="mt-2">Return to this tab and when you are ready we will then demonstrate the data has been retrieved by showing a small subset of data.</p>

          <p>This will be:</p>

          <ul>
            <li>your current employer (or one current employer if you have more than one)</li>
            <li>the amount of time you have been with that employer.</li>
          </ul>

          <Button onClick={() => doRedirect()}>Next</Button>

        </div>
      )}

      {page === 5 && (
        <div className="mt-4 text-start mx-4">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>           
          </div>
          <p>Processing, please wait...</p>
          <p>{statusMessage}</p>
        </div>
      )}

      {page === 6 && (
        <div className="mt-4 text-start mx-4" style={{ maxWidth: 640 }}>

          <p>Your current employer is <span className="fw-bold">{latestEmployer.name}</span></p>

          <p>You have been employed by <span className="fw-bold">{latestEmployer.name}</span> since <span className="fw-bold">{getStartDate(latestEmployer.start_date)}</span></p>

          <p>We have revoked all permissions and cleared any data used for this demo</p>         

          <Button onClick={() => setPage(1)}>Start again</Button>

        </div>
      )}  

      {page === 7 && (
        <div className="mt-4 text-start mx-4" style={{ maxWidth: 640 }}>

          <p>Error - {errorMessage}</p>

          <Button onClick={() => setPage(2)}>Try again</Button>

        </div>
      )}
    </div>
  );
}

export default App;
